import { useState, useEffect } from 'react'

const useViewportHeight = () => {
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    // To get the viewport height and respect the mobile browser's bar
    const updateVh = () => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    updateVh()
    setShouldRender(true)

    // Update vh on resize
    window.addEventListener('resize', updateVh)

    return () => window.removeEventListener('resize', updateVh)
  }, [])

  return shouldRender
}

export default useViewportHeight
