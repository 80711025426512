import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import moment from 'moment'
import axios from 'axios'
import { useTheme } from 'styled-components'

import { TitleM, BodyM, BodyS } from '../styles/Typography'
import { FooterStyled } from '../styles/FooterStyled'

import Button from './Button'
import LegalRow from './LegalRow'
import ArrowUpRight from './icons/ArrowUpRight'

/**
 * The main footer with a contact form.
 * @param {ComponentProps} props
 * @returns {StatelessComponent}
 */
const Footer = ({ isReverse }) => {
  const theme = useTheme()

  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')

  const isInvalid = email === '' || name === ''

  const handleSubmit = (e) => {
    e.preventDefault()

    if (isInvalid) {
      return false
    }

    const data = {
      email: email,
      fields: {
        name: name,
      },
      // groups: [`${process.env.GATSBY_MAILERLITE_DEFAULT_FORM_ID}`],
      groups: ['137288139599250646'],
    }

    axios({
      method: 'post',
      url: 'https://connect.mailerlite.com/api/subscribers',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${process.env.GATSBY_MAILERLITE_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          setMessage('This email already exists.')
        }

        if (response.status === 201) {
          navigate('/butik-subscribe-success')
          // setMessage('Thank you for subscribing!')
        }
      })
      .catch(function (response) {
        setMessage('Oops, something went wrong.')

        if (response.status === 422) {
          setMessage('The given data was invalid.')
        }
      })
  }

  return (
    <FooterStyled isReverse={isReverse}>
      <div className="form-container" id="subscribe">
        <div className="title-wrapper">
          <TitleM
            className="form-title"
            color={isReverse ? theme.textLight : theme.textPrimary}
          >
            Subscribe to
          </TitleM>
          <TitleM
            className="form-title last"
            color={isReverse ? theme.textLight : theme.textPrimary}
          >
            stay updated
          </TitleM>
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            value={email}
            placeholder="Email"
            onChange={({ target }) => setEmail(target.value)}
          />
          <input
            type="text"
            name="name"
            value={name}
            placeholder="First name"
            onChange={({ target }) => setName(target.value)}
          />
          <a className="btn" onClick={handleSubmit}>
            <Button isReverse={isReverse} isDisabled={isInvalid}>
              <ArrowUpRight
                color={isReverse ? theme.textLight : theme.textPrimary}
              />
            </Button>
          </a>
        </form>
        {message && <BodyM className="message">{message}</BodyM>}
      </div>
      <LegalRow isReverse={isReverse} />
    </FooterStyled>
  )
}

export default Footer
