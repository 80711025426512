import React from 'react'
import { useTheme } from 'styled-components'

import useViewportHeight from '../hooks/useViewportHeight'

import { CoverDefaultStyled } from '../styles/CoverStyled'
import { BodyM } from '../styles/Typography'

import ButikLogo from './icons/ButikLogo'
import ButikShapes from './icons/ButikShapes'
import ScrollDown from './icons/ScrollDown'

/**
 * The default cover.
 * @param {ComponentProps} props
 * @returns {FunctionComponent}
 */
const CoverDefault = () => {
  const theme = useTheme()

  const shouldRender = useViewportHeight()

  return shouldRender ? (
    <CoverDefaultStyled>
      <div className="visual">
        {/* <div className="shapes"><ButikShapes /></div> */}
        <div className="logo">
          <ButikLogo />
          <BodyM className="title hide-md" color={theme.textLight} weight={500}>
            tolmin, slovenia <br />9 + 10—13 july 2025
          </BodyM>
        </div>
      </div>
      <div className="info">
        <ScrollDown className="scroll-down" />
        <BodyM className="title hide-lg" color={theme.textLight} weight={500}>
          tolmin, slovenia <br />9 + 10—13 july 2025
        </BodyM>
      </div>
    </CoverDefaultStyled>
  ) : (
    <CoverDefaultStyled />
  )
}

export default CoverDefault
